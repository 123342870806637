function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 22C8 18.287 9.475 14.726 12.1005 12.1005C14.726 9.475 18.287 8 22 8"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M56 42C56 45.713 54.525 49.274 51.8995 51.8995C49.274 54.525 45.713 56 42 56"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M27.087 55.087L33.061 51.5L31.861 50.306L25.918 51.5C24.1492 48.9295 22.2434 46.4559 20.209 44.09L31.866 37.17L29.477 34.781L13.952 37.731L9.392 33.171C6.767 30.546 3.601 29.271 2.434 30.436C1.267 31.601 2.54401 34.769 5.16901 37.394L9.729 41.954L6.779 57.477L9.168 59.866L16.088 48.209C18.4539 50.2434 20.9275 52.1492 23.498 53.918L22.309 59.866L23.503 61.066L27.087 55.087Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M36.913 8.91296L30.939 12.5L32.139 13.694L38.082 12.5C39.8509 15.0705 41.7566 17.5441 43.791 19.91L32.134 26.83L34.523 29.219L50.048 26.269L54.608 30.829C57.233 33.454 60.399 34.729 61.566 33.564C62.733 32.399 61.456 29.231 58.831 26.606L54.271 22.046L57.221 6.52096L54.832 4.13396L47.912 15.791C45.5461 13.7566 43.0725 11.8508 40.502 10.082L41.691 4.13396L40.5 2.93896L36.913 8.91296Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
export default Logo;
