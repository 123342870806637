import React from "react";

/** Vendors */
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

/** Entry Point **/
import App from "./App";

/** Custom CSS */
import "../dist/css/app.css";
import "../dist/css/color.css";
import "../dist/css/format.css";
import "../dist/css/text.css";
import "../dist/css/spacing.css";
import "../dist/css/learnmore.css";
import "../dist/css/home.css";

const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_REDIRECT_URI,
} = process.env;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={REACT_APP_AUTH_DOMAIN!}
      clientId={REACT_APP_AUTH_CLIENT_ID!}
      redirectUri={REACT_APP_AUTH_REDIRECT_URI!}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
);
