import React from "react";

/** Vendor **/
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import {
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";

function ContactForm() {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm();

  function onSubmit(values: any) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        resolve();
      }, 3000);
    });
  }

  return (
    <Container maxW="container.md" mb={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex>
          <FormControl isInvalid={Boolean(errors.givenName)} pr={4} my={4}>
            <FormLabel>First name</FormLabel>
            <Input
              errorBorderColor="red.300"
              id="givenName"
              placeholder="Jane"
              _placeholder={{ opacity: 1, color: "gray.400" }}
              type="text"
              variant="outline"
              {...register("givenName", {
                required: "Required",
                minLength: {
                  value: 3,
                  message: "Minimum length should be 3",
                },
              })}
            />
            <FormErrorMessage>
              {(errors.givenName && errors.givenName.message) as string}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.familyName)} my={4}>
            <FormLabel>Last name</FormLabel>
            <Input
              errorBorderColor="red.300"
              id="familyName"
              placeholder="Doe"
              _placeholder={{ opacity: 1, color: "gray.400" }}
              type="text"
              variant="outline"
              {...register("familyName", {
                required: "Required",
                minLength: {
                  value: 3,
                  message: "Minimum length should be 3",
                },
              })}
            />
            <FormErrorMessage>
              {(errors.familyName && errors.familyName.message) as string}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <FormControl isInvalid={Boolean(errors.email)} my={4}>
          <FormLabel>Email</FormLabel>
          <Input
            errorBorderColor="red.300"
            id="email"
            placeholder="jane.doe@email.com"
            _placeholder={{ opacity: 1, color: "gray.400" }}
            type="email"
            variant="outline"
            {...register("email", {
              required: "Required",
              minLength: { value: 3, message: "Minimum length should be 3" },
            })}
          />
          <FormErrorMessage>
            {(errors.email && errors.email.message) as string}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.comments)} my={4}>
          <FormLabel>Comments</FormLabel>
          <Textarea
            errorBorderColor="red.300"
            id="comments"
            placeholder="Questions or comments"
            _placeholder={{ opacity: 1, color: "gray.400" }}
            variant="outline"
            {...register("comments", {
              required: "Required",
              minLength: { value: 3, message: "Minimum length should be 3" },
            })}
          />
          <FormErrorMessage>
            {(errors.comments && errors.comments.message) as string}
          </FormErrorMessage>
        </FormControl>
        <Center>
          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            my={4}
            rightIcon={<ArrowForwardIcon />}
            size="md"
            type="submit"
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  );
}
export default ContactForm;
