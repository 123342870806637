function Logo(): JSX.Element {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 18H57C58.3261 18 59.5979 18.5268 60.5355 19.4645C61.4732 20.4021 62 21.6739 62 23V43C62 43.6566 61.8707 44.3068 61.6194 44.9134C61.3681 45.52 60.9998 46.0712 60.5355 46.5355C60.0712 46.9998 59.52 47.3681 58.9134 47.6194C58.3068 47.8707 57.6566 48 57 48H54V58L40 48H28"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M45 5H7C5.67392 5 4.40215 5.52678 3.46447 6.46447C2.52678 7.40215 2 8.67392 2 10V33C2 34.3261 2.52678 35.5979 3.46447 36.5355C4.40215 37.4732 5.67392 38 7 38H12V52L28 38H45C46.3261 38 47.5979 37.4732 48.5355 36.5355C49.4732 35.5979 50 34.3261 50 33V10C50 8.67392 49.4732 7.40215 48.5355 6.46447C47.5979 5.52678 46.3261 5 45 5Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
export default Logo;
