function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.097 57.308C18.737 56.096 19.996 53.013 19.996 53.013C19.996 53.013 21.413 52.029 21.856 50.773C22.299 49.517 21.881 47.811 21.881 47.811C21.881 47.811 23.137 44.125 22.546 43.091C21.955 42.057 19.444 41.392 19.444 41.392C19.444 41.392 18.41 39.472 16.268 39.102C14.864 38.955 13.46 37.994 13.46 36C13.46 34.006 15.454 30.756 15.454 30.756C15.454 30.756 17.522 29.744 17.818 29.006C18.114 28.268 18.214 26.546 18.214 26.546C18.214 26.546 20.181 23.074 19.073 21.08C18.72 20.444 17.079 19.972 17.079 19.972L14.778 16.521"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M52.519 51.97C50.01 50.767 45.621 49.763 45.621 49.763C45.621 49.763 42.414 46.193 40.309 46.489C38.204 46.785 36.062 49.148 36.062 49.148C36.062 49.148 36.21 53.506 35.545 53.728C34.88 53.95 33.107 52.768 32 53.654C30.892 54.54 31.531 57.039 31.531 57.039C31.531 57.039 29.119 57.791 28.824 58.603C28.529 59.415 29.702 61.225 29.925 61.919"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M32.2 9.99996C32.133 9.99896 32.067 9.99996 32 9.99996C17.641 9.99996 6 21.641 6 36C6 50.359 17.641 62 32 62C46.359 62 58 50.359 58 36C58 31.265 56.734 26.825 54.523 23.002"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M42 22C47.5228 22 52 17.5228 52 12C52 6.47715 47.5228 2 42 2C36.4772 2 32 6.47715 32 12C32 17.5228 36.4772 22 42 22Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M42 22V34"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
export default Logo;
