function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 38L17 49L49 7"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M60 23H52"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M60 33H45"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M60 43H37"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M60 53H30"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
export default Logo;
