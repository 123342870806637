function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 49.057L14.695 57.63L18 38.36L4 24.714L23.348 21.902L32 4.37L40.652 21.902L60 24.714L54 30.562"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M50 61C56.6274 61 62 55.6274 62 49C62 42.3726 56.6274 37 50 37C43.3726 37 38 42.3726 38 49C38 55.6274 43.3726 61 50 61Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M50 45V53"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M46 49H54"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
export default Logo;
