import React, { Suspense } from "react";

/** Vendor **/
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ChakraProvider, extendTheme, Text } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";

/** Custom Components **/
import AboutUs from "../components/aboutus/views/AboutUs";
import AppLayout from "../components/shared/layout/AppLayout";
import NotFound from "../components/error/views/NotFound";
import Checkout from "../components/checkout/views/Checkout";
import Contact from "../components/contact/views/Contact";
import Home from "../components/home/views/Home";
import LearnMore from "../components/learnmore/views/LearnMore";
import Legal from "../components/legal/views/Legal";

/** CSS **/
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";

/** Types **/
import type { ReactElement } from "react";

function App(): ReactElement {
  const myTheme = extendTheme(
    {
      colors: {
        ...theme.colors,
        blue: {
          "50": "#E9EFFC",
          "100": "#C0D2F6",
          "200": "#98B6F1",
          "300": "#6F99EC",
          "400": "#477CE6",
          "500": "#1D5BD6",
          "600": "#184CB4",
          "700": "#123987",
          "800": "#0C265A",
          "900": "#06132D",
        },
        brand: { ...theme.colors.blue, 500: "#1D5BD6", 0: "#FFFFFF" },
      },
      fonts: {
        heading: `'Work Sans', sans-serif`,
        body: `'Work Sans', sans-serif`,
      },
    },
    theme
  );

  //TODO - Make a more eleglant loading for suspense
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ChakraProvider theme={myTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route path="" element={<Home />} />
              <Route path="about" element={<AboutUs />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="learn-more/:entity" element={<LearnMore />} />
              <Route
                path="learn-more"
                element={<Navigate to="/learn-more/traveler" replace />}
              />
              <Route path="legal" element={<Legal />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
              <Route
                path="*"
                element={<Text>There's nothing here - traveler: 404!</Text>}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </Suspense>
  );
}

export default App;
