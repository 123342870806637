function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 62V58L3.444 45.685C2.512 44.5974 1.99981 43.2123 2 41.78V23.5C2 22.5717 2.36875 21.6815 3.02513 21.0251C3.6815 20.3687 4.57174 20 5.5 20V20C6.42826 20 7.3185 20.3687 7.97487 21.0251C8.63125 21.6815 9 22.5717 9 23.5V35.13"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M26.0001 62V48.485C26 46.894 25.368 45.3681 24.2431 44.243L14.5001 34.5C14.1718 34.1716 13.7821 33.9111 13.3531 33.7334C12.9242 33.5557 12.4644 33.4642 12.0001 33.4642C11.5358 33.4642 11.076 33.5557 10.6471 33.7334C10.2181 33.9111 9.82838 34.1716 9.50011 34.5V34.5C9.17174 34.8283 8.91126 35.218 8.73354 35.647C8.55583 36.0759 8.46436 36.5357 8.46436 37C8.46436 37.4643 8.55583 37.9241 8.73354 38.353C8.91126 38.782 9.17174 39.1717 9.50011 39.5V39.5L17.0001 47"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M50 62V58L60.556 45.685C61.488 44.5974 62.0002 43.2123 62 41.78V23.5C62 22.5717 61.6312 21.6815 60.9749 21.0251C60.3185 20.3687 59.4283 20 58.5 20C57.5717 20 56.6815 20.3687 56.0251 21.0251C55.3688 21.6815 55 22.5717 55 23.5V23.5V35.13"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M38 62V48.485C38.0001 46.894 38.6321 45.3681 39.757 44.243L49.5 34.5C49.8283 34.1716 50.218 33.9111 50.647 33.7334C51.0759 33.5557 51.5357 33.4642 52 33.4642C52.4643 33.4642 52.9241 33.5557 53.353 33.7334C53.782 33.9111 54.1717 34.1716 54.5 34.5V34.5C54.8284 34.8283 55.0889 35.218 55.2666 35.647C55.4443 36.0759 55.5358 36.5357 55.5358 37C55.5358 37.4643 55.4443 37.9241 55.2666 38.353C55.0889 38.782 54.8284 39.1717 54.5 39.5V39.5L47 47"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M32 3L19 13V30H28V21H36V30H45V13L32 3Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
export default Logo;
