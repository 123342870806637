import React from "react";

/** Vendor **/
import { Outlet } from "react-router-dom";

/** Custom Components **/
import Navbar from "../navbar/views/Navbar";
import Footer from "../footer/views/Footer";

function AppLayout() {
  return (
    <React.Fragment>
      <Navbar />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}
export default AppLayout;
