function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 4V20"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M24 12H40"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M50 42C53.3137 42 56 39.3137 56 36C56 32.6863 53.3137 30 50 30C46.6863 30 44 32.6863 44 36C44 39.3137 46.6863 42 50 42Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M14 42C17.3137 42 20 39.3137 20 36C20 32.6863 17.3137 30 14 30C10.6863 30 8 32.6863 8 36C8 39.3137 10.6863 42 14 42Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M26 60V54.035C26 52.615 25.255 51.309 24.033 50.586C22.088 49.435 18.716 48 14 48C9.223 48 5.873 49.426 3.948 50.575C2.736 51.299 2 52.601 2 54.013V60H26Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        d="M62 60V54.035C62 52.615 61.255 51.309 60.033 50.586C58.088 49.435 54.716 48 50 48C45.223 48 41.873 49.426 39.948 50.575C38.736 51.299 38 52.601 38 54.013V60H62Z"
        stroke="#212121"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
}
export default Logo;
